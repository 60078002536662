<template>
  <v-container>
    <language-switcher floating />
    <validation-observer v-slot="{ valid }">
      <v-form @submit.prevent="login">
        <div class="login">
          <h1>{{ $t("password-recover") }}</h1>
          <p>{{ $t("password-recover-text") }}</p>
          <v-row no-gutters>
            <v-col>
              <validation-provider
                v-slot="{ errors }"
                :name="$t('email').toLowerCase()"
                :rules="{ required: true, email: true }"
              >
                <v-text-field
                  v-model="request.email"
                  outlined
                  :label="$t('email')"
                  :error-messages="errors"
                >
                  <template #[`prepend-inner`]>
                    <v-img max-width="22px" :src="require(`@/assets/message.svg`)" />
                  </template>
                </v-text-field>
              </validation-provider>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="2" class="text-right mt-10 pt-10">
              <v-btn color="#E6E6E6" elevation="0" block :to="{ name: 'login' }">
                <v-img :src="require('@/assets/arrow-left.svg')" />
              </v-btn>
            </v-col>
            <v-col cols="1" class="text-right mt-10 pt-10"> </v-col>
            <v-col class="text-right mt-10 pt-10">
              <v-btn color="primary" type="submit" :disabled="!valid" elevation="0" block>
                {{ $t("submit") }}
              </v-btn>
            </v-col>
          </v-row>
        </div>
      </v-form>
    </validation-observer>
  </v-container>
</template>

<script>
import LanguageSwitcher from "../components/LanguageSwitcher";

export default {
  mixins: [],
  components: {
    LanguageSwitcher,
  },
  data: () => ({
    request: {
      email: "",
    },
  }),
  computed: {},
  created: function () {},
  methods: {
    async login() {
      try {
        await this.$api.post("/auth/recovery", this.request);

        await this.$store.dispatch(
          "notificator/success",
          this.$t("we-have-sent-an-email-confirm")
        );
      } catch (error) {
        await this.$store.dispatch("notificator/errorResponse", error);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.container {
  display: flex;
  min-height: 100vh;
  justify-content: center;
  align-items: center;

  .login {
    width: 342px;

    h1 {
      color: #1d3557;
      font-weight: 900;
      font-size: 35px;
      line-height: 120%;
    }

    .v-btn {
      font-weight: 500;
      font-size: 18px;
    }
  }
}
</style>
